@import "node_modules/bootstrap/scss/bootstrap";
@import "./Scrollbars";
@import "./Variables";
@import "./Borders";
@import "./Positioning";
@import "./Backgrounds";
@import "./NabTabs";
@import "./Overflow";
@import "./Sizing";
@import "./Spacing";
@import "./Grid";
@import "./Text";
@import "./subscription/Offer";
@import "./Mui/MuiAccordion.scss";
@import "./Swal/style.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  background-color: #f5f5f5;
}
.margin0 {
  margin: 0;
}
.ml-0 {
 margin-left: 0;
}
.mr-0 {
  margin-right: 0;
}
.mrt-2 {
  margin-top: 2px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-65 {
  margin-top: 65px!important;
}
.mt-70 {
  margin-top: 70px!important;
}
.mt-75 {
  margin-top: 75px!important;
}
.mt-90 {
  margin-top: 90px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-110 {
  margin-top: 110px;
}
.mt-120 {
  margin-top: 120px!important;
}
.mt-160 {
  margin-top: 160px!important;
}
.mt-175 {
  margin-top: 175px!important;
}
.mt-220 {
  margin-top: 220px!important;
}
.mt-7 {
  margin-top: 7px;
}
.mt-12 {
  margin-top: 12px;
}
.mb-7 {
  margin-bottom: 7px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-8 {
  margin-bottom: 4.5rem;
}
.mb-11 {
  margin-bottom: 6rem;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-110 {
  margin-bottom: 110px;
}
.mtm-5 {
  margin-top: -5px;
}
.padding-0 {
  padding: 0;
}
.pl-0{
  padding-left: 0;
}
.pl-8 {
  padding-left: 8px;
}
.pl-30{
  padding-left: 30px;
}
.pdr-5 {
  padding-right: 5px;
}
.pr-30{
  padding-right: 30px;
}
.pr-40{
  padding-right: 40px!important;
}
.pl-15 {
  padding-left: 15px;
}
.pr-15 {
  padding-right: 15px;
}
.bottom-60i {
  bottom: 60px!important;
}

.report-modal-body {
  max-height: calc(100vh - 278px);
} 
.h-320 {
  height: 320px;
}
.hidden {
  visibility: hidden;
  display: none;
}
.nowrap, .text-nowrap {
  white-space: nowrap;
}
.size-20 {
  font-size: 20px;
}
.size-18 {
  font-size: 18px;
}
.size-15 {
  font-size: 15px;
}
.size-14 {
  font-size: 14px;
}
.size-13 {
  font-size: 13px;
}
.size-12 {
  font-size: 12px;
}
.size-11 {
  font-size: 11px;
}
.size-10 {
  font-size: 10px;
}
.size0_85rm {
  font-size: .85rem;
}
.size0_80rm {
  font-size: .80rem;
}
.text-sm {
  font-size: 0.875rem;
}
.weight-500 {
  font-weight: 500;
}
.weight-600 {
  font-weight: 600;
}
.text-green {
  color: green;
}
.text-green-1 {
  color: #48b234;
}
.text-red {
  color: red;
}
.text-grey {
  color: #afafaf;
}
.text-grey-dark {
  color: #5d5d5d;
}
.text-underline {
  text-decoration: underline;
}
.rounded-left-0 {
  border-top-left-radius: 0!important;
  border-bottom-left-radius: 0!important;
}

.table td.border-black{
  border-color: #000;
}
.table td.border-top-blue2x {
  border-top: 2px solid #6e78f7;
}
.card--material__content {
  color: rgba(0, 0, 0);
}
.pull-left{
  float: left;
}
.modal_footer.flex-content-end {
  justify-content: flex-end;
}
label.block {
  display: block;
}
.display-table {
  display: flex;
}
.display-table-cell {
  /* display: table-cell; */
  width: 100%;
}
.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
.text-ellipsis {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  vertical-align: top;
}
hr {
  margin: 5px 0px;
}
.shadow-top {
  box-shadow: 0 .85rem .5rem 1rem rgba(0,0,0,.15)!important;
}
.capitalize {
  text-transform: capitalize;
}
.table thead th.v-align-middle {
  vertical-align: middle;
}
.position-relative {
  position: relative;
}
.height-auto {
  height: auto!important;
}
.img-responsive{
  max-width: 100%;
}
.form-control{
  /* min-height: 45px; */
}
.table-pad-10 td, .table-pad-10 th {
  padding: 10px;
}
.overflow-x-auto {
  overflow-x: auto;
}
ons-range, ons-select{
  width: 100%;
}
ons-page.page.page--material.page.page-white .page__content.page--material__content {
  /* background-color: white !important;
  background: white !important; */
}
.sliderimage{
  margin: 0 auto;
  text-align: center; 
}
.sliderimage img{
  margin: 0 auto;
  text-align: center; 
}
.slidercontent_parent{
  position: relative;
  height: 100vh;
}
.slidercontent_inner_parent{
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
}
body .slick-slider{
  overflow: hidden;
  /* height: 100vh; */
  padding: 0 0px;
}
.slider_heading h1 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #3f4079;
  margin-top: 25px;
  margin-bottom: 0;
}
.slider_heading p{
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.14px;
  color: #313450;
  max-width: 180px;
  text-align: center;
  margin: 0 auto;
}
.next_slide_button{
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px;
  border: solid 2px #e5d6ff;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 45px;
  letter-spacing: 1.6px;
  text-align: center;
  color: #11b232;
  width: 168.2px;
  height: 50px;
}
.next_slide_button:focus, .next_slide_button:hover, .next_slide_button:active{
  outline: none;
  box-shadow: none;  
}
.list-item--material{
  /* padding-left: 0px; */
}
.center.list-item__center.list-item--material__center{
  padding-left: 32px;
  font-family: "Poppins";
  background-image: none;
}
.list-item--material__center, .list-item--material__left:empty{
  padding: 20px 6px 20px 0;
}
.side-menu .center.list-item__center.list-item--material__center {
  padding: 18px 6px 18px 0px;
}
.side-menu .list-item .list-item__left img,
.side-menu .MuiListItemIcon-root img {
  // max-width: 22px;
}
.sidebar-navigation .list-item a {
  width: 100%;
}
.user-section-list{
  background-image: linear-gradient(61deg, #11b232 8%, #6e78f7 91%);
  color: #fff;
  font-family: "Poppins";
  padding: 0;
}
.user-section-list .list-item__center{
  justify-content: center;
}
.user-section-list .center.list-item__center.list-item--material__center{
  padding-left: 0;
}
ons-list.list.list--list-bottom.list--material {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.user-avtar-box {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
}
.user-avtar-box img {
  border-radius: 50%;
}
.user-detail.text-center {
  margin-top: 10px;
}
.user-name {
  font-size: 1.45rem;
  padding: 0px 10px;
  line-height: 1.2;
}
.user-mobile {
  font-size: 17px;
  margin-top: 10px;
}
.comman_header{
  background-image: url("images/bg-kisan.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.comman_header h1{
  color: #ffffff;
  font-size: 24px;
  margin: 0;
}
.registration_header{
  background-image: linear-gradient(44deg, #11b232 10%, #6e78f7 102%);
  border-radius: 0px 0px 0.7rem 0.7rem;
}
.divSignupForm{
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 12px 29px 0 rgba(0, 0, 0, 0.09);
  padding: 15px 0px;
}
.signup_page {
  margin-top: 110px;
}
.login_return .comman_header{
  padding: 0;
  min-height: 50vh;
  position: relative;
  border-radius: 0px 0px 30px 30px;
}
.login_return .comman_header h1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login_return .comman_header h1 img {
  max-width: 150px;
}
.inner_header,
.inner_header_small {
  width: 100%;
  height: 56px;
  border-radius: 0 0 0.7rem 0.7rem;
  background-image: linear-gradient(61deg, #11b232 8%, #6e78f7 91%);
  position: absolute;
  top: 0;
}
.inner_header > img.img-responsive {
  margin: 0 auto 11px auto;
  display: block;
}
.landing_page_logo {
  height: 83px;
  padding-top: 10px;
  text-align: center;
}
.landing_page_logo img {
  max-width: 90px;
}
.inner_header_payment{
    width: 100%;
    height: 120px;
    border-radius: 0 0 0.7rem 0.7rem;
    background-image: linear-gradient(61deg, #11b232 8%, #6e78f7 91%);
    position: absolute;
    top: 0;  
    z-index: -1;
}
.inner_header_payment .heading {
  font-size: 17px;
  font-weight: bold;
  line-height: 1.53;
  color: #ffffff;
  margin-left: 0;
  margin-top: 15px;
  width: 100%;
  text-align: center;
}
.inner_header_payment > img.img-responsive {
  margin: 0 auto 11px auto;
  display: block;
}
ul.landing-menu {
  list-style-type: none;
  padding: 0;
  text-align: center;
  vertical-align: top;
}
ul.landing-menu.dairy-main-menu {
  margin-top: 24px;
  overflow-y: hidden;
  height: 75px;
  overflow-x: scroll;
  white-space: pre;
  white-space: nowrap;
  width: 100%;
}
ul.landing-menu li {
  display: inline-block;
  max-width: 150px;
  width: 150px;
  vertical-align: top;
}
ul.landing-menu li a {
  display: block;
  padding: 0 15px;
  color: #333348;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}
ul.landing-menu span {
  display: block;
  font-size: 12px;
  color: #898a8f;
  font-weight: normal;
  margin-top: 2px;
}
ul.dairy-main-menu li {
  display: inline-block;
  max-width: max-content;
  vertical-align: middle;
  margin: 0 2px;
}
ul.dairy-main-menu li a {
  display: block;
  padding: 0 5px;
  color: #333348;
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
}
ul.landing-menu li .circle-image {
  width: 75px;
  height: 75px;
  box-shadow: 0 11px 35px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto 5px;
}
ul.dairy-main-menu li .circle-image {
  width: 43px;
  height: 43px;
  box-shadow: 0 11px 35px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  padding: 8px 0px;
}
.circle-image label{
  color: #11b232;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}
.circle-image {
  width: 75px;
  height: 75px;
  box-shadow: 0 11px 35px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
}
.circle-image img{
width: 45px;
margin-top: 13px;
}
ul.dairy-main-menu li .circle-image img{
  width: 26px;
  margin-top: unset;
}
.circle-image.weather img{
  margin-top: 4px;
}
.landing-promotional-slider {
  padding-top: 10px;
}
.landing-promotional-slider .inner-slide {
  /* max-width: 250px; */
  position: relative;
  /* margin: 15px; */
}
.landing-promotional-slider .slick-slide{
   padding: 0 10px;
}
body .landing-promotional-slider .slick-slider{
  padding: 0 15px;
  height: auto;
}
.landing-promotional-slider .slide-img{
  /* width: 340px; */
  border-radius: 11px;
}
.landing-promotional-slider .slide-img:after {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 11px;;
  left: 0;
  z-index: 1;
}
input.form-control[type="submit"] {
  background: #11b232;
  color: #fff;
  border-radius: 34px;
  height: auto;
  padding: 10px 15px;
  font-size: 20px;
}
a.google-plus,
a.google-plus:focus {
  background: #ffffff;
  border: 1px solid #db4c3e;
  color: #db4c3e;
}
a.google-plus:active,
a.google-plus:hover {
  background-color: #db4c3e!important;
  border-color: #db4c3e!important;
  color: #ffffff;
}
a.google-plus:focus {
  box-shadow: 0 0 0 0.2rem #db4c3e80 !important;
}
.landing-promotional-slider .slide-img img{
  border-radius: 11px;
}
.landing-promotional-slider .slide-heading{
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2.05px;
  text-align: left;
  color: #fff;
}
.landing-promotional-slider .slide-desc {
  font-size: 9px;
  line-height: 1.44;
  color:#e4e4e4;
}
.landing-promotional-slider .slide-detail {
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 9;
  max-width: 150px;
}
.comman_footer{
  width: 100%;
  background: #fff;
  border-radius: 18px 18px 0 0;
  height: 87px;
  box-shadow: 0 -4px 63px 0 rgba(0, 0, 0, 0.07);
}
.login_screen_button {
  display: block;
  text-align: center;
  max-width: 375px;
  padding: 10px 20px;
  border-radius: 25px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ececec;
  background-color: #ffffff;
  margin-bottom: 35px;
  width: 100%;
  color: #3f4079;
  outline: none;
}
button:focus{
  outline: none;
}

.login_screen_button img {
  max-height: 23px;
  margin-right: 10px;
}
.login_screen_button.googlebutton{
  margin-bottom: 15px;
}
.loginmain_page{
  margin-top: 48px;
}
.btn-small {
  line-height: 23px;
  min-height: 23px;
  font-size: 16px;
}
.btn-m-small{
  line-height: 30px;
  min-height: 30px;
}
.agreetc{
  font-family: "Poppins";
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #898a8f;
}
.m_login_header{
  padding-top: 60px;
  margin-bottom: 52px;
}
.m_login_header img, .m_login_otp_pass_header img {
  max-width: 125px;
}
.login_mobile{
  height: 100vh;
}
.m_login_header p, .m_login_otp_pass_header p {
  margin: 25px auto 0 auto;
  max-width: 232px;
  font-size: 12px;
  color: #898a8f;
  line-height: 1.5;
}
.inline_input .inline {
  display: inline-block;
  vertical-align: middle;  
}
.inline_input .country_code {
  width: 50px;
}
.inline_input .numberfield {
  width: calc(100% - 65px);
  margin-left: 15px;
}
.loginmobileform .divSignupForm{
  padding: 30px 15px 6px;
}
.enter_code_heading{
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;;
  color: #333348;
}
.inline_input_otp input{
  background-color: #fbfbfb;
  border: solid 1px #c9c9c9;
  height: auto;
  text-align: center;
  line-height: inherit;
  margin: 0 10px;
  width: 100%!important;
  height: 45px;
}
.not_recived p{
  margin-top: 19px;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #313450;
}
.news-inner-slide {
  /* width: 125px; */
  border-radius: 8px;
  /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16); */
  background-color: #ffffff;
}
.news-inner-slide img{
  border-radius: 8px 8px 0 0;
}
.news-slide-heading{
  padding: 5px;
  min-height: 110px;
}
.news-slide-heading h3 {
  font-size: 12px;
  font-weight: 600;
  color: #313450;
  margin: 0;
  height: 44px;
  overflow: hidden;
}
.news-time {
  font-size: 9px;
  font-weight: 500;
  color: #898a8f;
  display: block;
  margin-top: 2px;
  position: relative;
}
span.news-time:after {
  content: '';
  width: 50%;
  background: #d1d1d1;
  height: 1px;
  left: 0;
  position: absolute;
  bottom: -1px;
}
.news-description {
  font-size: 10px;
  color: #4d4d4d;
  padding-top: 5px;
  margin: 0;
  height: 52px;
  overflow: hidden;
}
.landing-news-slider .slick-slide{
  padding: 0 10px;
}
.News-Updates h4 {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #3f4079;
  margin: 0;
}
.show-more a{
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  color: #11b232;
  display: block;
  outline: none;
}
.news-main {
  padding: 15px 10px;
}
body .landing-news-slider .slick-slider{
  padding: 0 15px;
  height: auto;
}
.feature-container{
  margin-top: 22px;
  padding: 0 25px;
  margin-bottom: 20px;
}
.mt-40{margin-top: 40px;}
.main-section.gestation-container {
  margin-top: 150px;
  padding: 0;
}
.gestaion-card {
  margin: 15px;
  border-radius: 10px;
  padding:15px;
  background-color: #ffffff;
  position: relative;
}
.gestaion-card .gesation-heading {
  font-size: 14px;
  line-height: 1.6;
  color: #4d4d4d;
  margin: 0;
}
.gestation-img-box{
  width: 70px;
  height: 70px;
  margin: 0 auto;
  border-radius: 50%;
  background: #ddd;
  overflow: hidden;
}
.gestation-card {
  margin: 0px 10px;
  margin-bottom: 15px;
}
.gesation-content {
  border-radius: 4px;
  background-color: #ececec;
  padding: 5px;
  font-size: 12px;
  margin-top: 15px;
}
.gestation-action {
  justify-content: center;
}
.gesation-cicular-dropdown .dropdown-toggle {
  background: white;
  width: 36px;
  height: 36px;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  box-shadow: none;
}
.gesation-cicular-dropdown .dropdown-toggle:after{
  content: none;
}
.gesation-cicular-dropdown .dropdown-toggle i{
 color:#11b232;
}
.dropdown.vert-dropdown.dropdown_zindex {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 15px;
}
.vert-dropdown button.dropdown-toggle {
  background: none;
  border: none;
}
.vert-dropdown button.dropdown-toggle::after{
  display: none;
}
.t-card{
  border-radius: 0.25rem;
  padding: .65rem;
  background-color: #ffffff;
  position: relative;
}
.t-card .t-heading {
  font-size: 14px;
  line-height: 1.6;
  color: #4d4d4d;
  margin: 0;
}
.t-content {
  padding-top: 7px;
  max-height: 325px;
  overflow: auto;
  position: relative;
}
.t-content table {
  font-size: 12px;
}
.t-content .table thead th {
  padding-top: 5px;
  padding-bottom: 5px;
  border: 0;
  font-weight: 500;
}
.t-content .table tbody td {
  font-size: 12px;
  padding: 5px 5px;
}
.cp-total {
  font-weight: 700;
  font-size: 15px;
}
form#filtermilkData {
  overflow: auto;
  height: 89%;
}
.dropdown-item {
  border-bottom: 1px solid #eee;
}
.dropdown-item:last-child {
  border-bottom: 0;
}
.dropdown.gesation-cicular-dropdown {
  position: absolute;
  right: 10px;
  top: 5px;
}
.dropdown.gesation-cicular-dropdown .dropdown-item{
color: #4D4D4D;
font-size: 13px;
}
.dropdown.gesation-cicular-dropdown.add-gest {
  top: 40px;
  right: 25px;
}
.modal-backdrop {
  z-index: -1;
}
.GestationModal .modal-content{
  background: transparent;
  border:none;
}
.GestationModal .modal-header{border: none;}
.GestationModal .modal-title {
  color: #fff;
  max-width: 300px;
  white-space: normal;
  font-weight: bold;
  margin: 0;
}
.GestationModal .modal-header .close,
.GestationModal .modal-footer .close {
  margin: 0;
  background: #fff;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  padding: 0;
  color: #11B232;
  opacity: 1;
}
.GestationModal .modal-body{
  background: #fff;
  border-radius: 20px;
  height: 85vh;
  overflow-y: auto;
  color: #000;
}
#editcustomerModal .modal-body {
  height: auto;
}
.GestationModal.filterModal .modal-body{
  background: transparent;
  position: relative;
}
.GestationModal.filterModal .modal-header{
  padding-bottom: 0px;
}
.GestationModal.filterModal .modal-footer{
  position: absolute;
  width: 100%;
  bottom: 0px;
  box-sizing: border-box;
  display: inline-block;
  left: 0;
  text-align: left;
}
.GestationModal.filterModal .modal-footer .btn {
  margin: unset;
  display: inline-block;
}
.GestationModal.filterModal .modal-footer .btn[type="submit"] {
  width: 80%;
}
.GestationModal.filterModal .modal-body .form-group{
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.27);
  padding: 15px 20px;
  margin-bottom: 6.8px;
}
button.btn.btn-cancel {
  color: #fff;
}
.GestationModal.filterModal .modal-body .form-group .radio {
  display: inline-block;
  text-align: left;
  width: 50%;
}
.GestationModal.filterModal .modal-body .form-group .radio input {
  margin-right: 10px;
  vertical-align: middle;
}
.modalwrapper{
  height: 600px;
  overflow-y: auto;
}
.GestationModal .modal-footer{
border: none;
}
.GestationModal .modal-footer button{
  margin: 0 auto;
  display: block;
}
button.btn.btn-primary {
  background: #11B232;
  border: none;
  border-radius: 150px;
  height: 45px;
  font-size: 17px;
  font-weight: 500;
}
.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgb(68, 167, 30);
}
.GestationModal label {
  font-weight: 500;
  color: #1A1A1C;
  font-size: 14px;
  display: block;
  text-align: left;
}
.form-check.form-check-inline label {
  font-weight: normal;
  color: #313450;
  margin-bottom: 0!important;
}
.GestationModal .form-control{
    border: 1px solid #D6D6D6;
    min-height: 45px;
    border-radius: 10px;
}
.GestationModal .form-group{
  margin-top: 0px;
  margin-bottom: 20px;
}
.form-control:focus{
  box-shadow: 0 0 0 0.2rem rgba(218, 218, 218, 0.25);
}
.custom-file-upload {
  position: relative;
  overflow: hidden;
}
.custom-file-upload input{
  position: absolute;
  font-size: 35px;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
}
.gest-default-img {
  display: inline-block;
  margin-right: 5px;
  width: 60px;
  height: 60px;
}
.gest-default-img img {
border-radius: 50px;
    width: 60px;
    height: 60px;
}
.custom-file-upload {
  position: relative;
  overflow: hidden;
  background: transparent;
  border: none;
  color: #1A1A1C;
  font-weight: 400;
  display: inline-block;
  top: 5px;
  width: 100%;
}
.GestationModal .custom-file-upload.btn-primary:active,.GestationModal .custom-file-upload.btn-primary:focus,.GestationModal .custom-file-upload.btn-primary:hover,.GestationModal .custom-file-upload.btn-primary:not(:disabled):not(.disabled):active {
  background: transparent;
  border: none;
  color: #1A1A1C;
}
/* customer page css start here  */
.search_customer {
  max-width: 500px;
  margin: 45px auto 0 auto;
  padding: 0 20px;
}
.search_customer .input-group>.form-control{
  width: 84%;
  flex: inherit;
}
.search_customer .input-group>.form-control:focus, .search_customer .input-group>.form-control:hover{
  outline: none;
  box-shadow: none !important; 
}
.customer_detail_listing {
  padding: 0 20px;
}
.listing_box_panel {  
  border-radius: 12px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 15px;
  position: relative;
}
.customer_detail_listing:last-child{
  margin-bottom: 0;
}
.customer_name h3 {
  font-family: Poppins;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  width: 86%;
  margin-top: 0;
}
.customer_info p{
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0;
  text-transform: capitalize;
}
form.add_customer_form .form-group {
  position: relative;
}
.personal_rate{
  position: absolute;
  right: 15px;
  top: 0px;
}

.auto_suggestion_number {
  position: absolute;
  top: 100%;
  background: #fff;
  width: calc(100% - 30px);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  border-radius: 4px;
  margin-top: 6px;
  z-index: 99;
}
.auto_suggestion_number ul{
  margin-bottom: 0;
  padding: 0;
}
.auto_suggestion_number ul li {
  padding: 5px 20px;
  list-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}
.nodata_available p {
  text-align: center;
  margin-top: 35px;
}
.whitebox_form {
  background: #fff;
  padding: 12px 0px;
  border-radius: 8px;
  margin-bottom: 35px;
}
form label {
  font-weight: bold;
  position: relative;
}
.weather_current_day{
  height: 55vh;
  background-color: #D6D6D6;
}
.next_day_weathers > h2 {
  height: 28px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #313450;
}
.next_day_weathers .panel_table {
  border-radius: 20px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.09);
  background-color: #f5f5f5;
}
.panel_table .table-responsive {
  border-radius: 10px;
  background: #fff;
}
.panel_table .table-responsive table.table {
  margin-bottom: 0;
}
.repeat_panel_box{
  padding: 20px 5px 1px;
  width: 92%;
  margin: 0 auto 15px auto;
  border-radius: 20px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
}
.remove_repeat_panel_box{
  position: absolute;
  top:0;
  right: 17px;
  z-index:99;
  color: red;
}
.add_customer_input{
  height: 45px;
  border-radius: 9px;
  border: solid 1px #d6d6d6;
}
.mobile_prefix {
  width: 45px;
  height: 44px;
  background-color: #dedede;
  display: inline-block;
  position: absolute;
  border-radius: 9px 0px 0 9px;
  text-align: center;
  line-height: 44px;
  top: 0.5px;
  left: 0px;
}
.icon-address-book {
  position: absolute;
  bottom: 0px;
  right: 0;
  margin-right: 1px;
  font-size: 25px;
  width: 40px;
  height: 45px;
  text-align: center;
}
.icon-address-book .ons-icon {
  vertical-align: sub;
}
.weather_image .weather_image_icon {
  max-width: 138px;
}
.current_city_temp{
  text-align: center;
}
p.current_temp {
  height: 56px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0;
}
p.current_city {
  height: 28px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0;
}
p.weather_status {
  height: 47px;
  font-family: Poppins;
  font-size: 33px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  color: #ffffff;
  margin: 0;
}
.next_day_weathers .weather_image_icon{
  max-width: 24px;
  margin-right: 10px;
}
.panel_table .table-striped tbody tr {
  border-bottom: 1px solid #f5f5f5;
}
.panel_table .table-striped tbody tr:last-child{
  border-bottom: none;
}
.panel_table table tr td:nth-child(1) {
  padding-left: 25px;
}
.panel_table table tr td:nth-child(3) {
  padding-right: 25px;
}
ul.nav li a.nav-link{
  color: #000;
}
.tab-pane.with-border{
  border: 1px solid #5cb848;
}
h2.tab_content_heading {
  padding-left: 20px;
  margin-top: 25px;
}
.milkdetail_tab .tab-pane {
  padding: 0;
}
.milkdetail_tab .tab-pane table.table {
  margin-bottom: 0;
}
.table-striped thead tr, 
.table-green-header thead tr {
  background: #5cb848;
  color: #fff;
}
.table_parent {
  margin: 15px 8px;
}
.table_parent.table_scrollable {
  overflow: auto;
}
.table_parent.table_scrollable .table {
  width: auto;
  max-width: inherit;
  overflow-x: auto;
}
.table_parent.table_scrollable .table tr th, 
.table_parent.table_scrollable .table tr td {
  min-width: 100px;
}
.table_parent.table_scrollable .table tr td:first-child {
  min-width: 160px;
}
.search_customer.remove_margintop{
  margin-top: 0;
}
.table_parent.table_scrollable .table tr td p {
  margin-bottom: 0;
  opacity: 0.25;
}
.dropdown.gesation-cicular-dropdown.dropdown_zindex{
  z-index: 9;
}
.dashed_line {
  margin: 15px 0;
  border: 1px dashed #0003;
}
.expiry_membership_message_row h3 {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  text-align: center;
  color: #898a8f;
  margin: 20px 0 35px 0;
}
.mb_renew_button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -16px;
}
.mb_renew_button button {
  border-radius: 25px;
  background-color: #11b232;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: #ffffff;
  padding: 6px 25px;
  border: none;
  width: 162px;
}
.mb_renew_button button i {
  margin-right: 9px;
}

.toast {
  max-width: none;
  background-color: rgba(0,0,0,.8);
  overflow: auto;
  font-size: initial;
  opacity: unset;
  box-shadow: none;
  background-clip: unset;
  border: none;
  border-radius: unset;
  backdrop-filter: none;
}

ons-speed-dial-item.fab.speed-dial__item.fab--with-text{
  width: auto!important;
  height: auto;
  line-height: 25px;
  border-radius: 3px;
  box-shadow: 0 1px 1px 1px rgba(0,0,0,.14), 0 1px 0px 0 rgba(0,0,0,.12), 0 1px 2px -1px rgba(0,0,0,.4);
  word-break: unset;
  white-space: nowrap;
  font-size: 14px;
  padding: 0px 8px;
}

span.input-group-addon {
  padding: 0px 5px;
  font-size: 1rem;
  font-weight: 400;
  /* line-height: 1; */
  color: #55595c;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid #ccc;
  /* border-radius: .25rem; */
  line-height: 43px;
}

.filtermodal{
  display: table;
  z-index: 10001;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #00000073;
}
.gestation-filter-moadal .modal-body .form-group .radio{
  width: 33%!important;
}
.dairy_settings span.input-group-addon{
  font-size: 14px;
  line-height: 40px;
}
.speed-dial.fab--material,
.speed-dial--material.speed-dial {
  position: fixed;
}
.inner_header.fixed-header, .inner_header_small.fixed-header {
  position: fixed;
  z-index: 9991;
  opacity: 1;
}
.btn-pregnancy-config{
  margin-top: 23px;
  padding: 9px;
  height: auto;
  border-radius: 16px;
  font-size: 15px;
}
.no_data {
  /* width: 109px; */
  margin: 10px auto;
  text-align: center;
}
.no_data span{
  display: inline-block;
  margin-top: 5px;
  color: #a2a2a2;
}
.language_header .inner_header{
  background-image: none;
  background-color: #51c569;
}
.choose_language.choose_language_box {
  border-radius: 23px;
  box-shadow: 0 3px 29px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  width: 88%;
  padding: 20px 16px;
  margin: 0 auto;
}
h1.choose_language_heading{
  margin: 0 0 15px 0;
  padding-left: 26px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #485b6e;
}


/* Radio Css  */

.theme_radio_design .inputGroup {
  background-color: #fff;
  display: block;
  margin: 0px 0;
  position: relative;
}
.theme_radio_design .inputGroup label {
  padding: 15px 15px;
  width: 100%;
  display: block;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #898a8f;
  border-top: 1px solid #f5f5f5;
  margin-bottom: 0;
}
.app-slider-languages-list .inputGroup:first-child label {
  border-top: none;
}
.theme_radio_design .inputGroup label:before {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: '';
  background-color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
          transform: translate(-50%, -50%) scale3d(1, 1, 1);
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}
.theme_radio_design .inputGroup label:after {
  width: 26px;
  height: 26px;
  content: '';
  border: 2px solid #D1D7DC;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: -1px 0px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}
.theme_radio_design .inputGroup label[for="hindi"] {
  border: none;
}
.theme_radio_design .inputGroup input:checked ~ label:before {
  -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
          transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.theme_radio_design .inputGroup input:checked ~ label:after {
  background-color: #27ae60;
  border-color: #27ae60;
}
.theme_radio_design .inputGroup input {
  height: 32px;
  -ms-flex-order: 1;
  order: 1;
  z-index: 9;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 100%;
  opacity: 0;
}
.language_choose_selection button.btn.btn-primary {
  max-width: 85%;
  margin: 15px auto 0px auto;
  display: block;
}
.toolbar--tk-toolbar {
  background-color: transparent;
  box-shadow: none;
}
.toolbar--tk-toolbar .toolbar-button--material{
  color: #fff;
}
.toolbar--tk-toolbar .back-button--material{
  color: #fff;
}
.toolbar--tk-toolbar .back-button--material__icon{
  fill: #fff;
}
.radio-button--form-check-input,
.checkbox--form-check-input,
.radio--form-check-input {
  margin-right: 7px;
}
.radio-button--material {
  line-height: 18px;
}
:checked+.radio-button--material__checkmark:before{
  border: 2px solid #56b333;
}
:checked+.radio-button--material__checkmark:after{
  background: #56b333;
}
:checked+.checkbox--material__checkmark:before{
  border: 2px solid #56b333;
  background-color: #56b333;
}
:checked+.checkbox--material__checkmark:after{
  top: 5px;
  left: 4px;
}
.select-all{
  float:left;
}
.select-all .RedButton{
  margin-bottom: 5px;
}
.table_container table tbody tr td input{
  margin-right: 10px;
}

.table-striped tfoot tr{
  background: #c7c4c4;
}
div.table_container{
  overflow: auto;
  max-height: 80vh;
}

div.table_container .table {
  font-size: 13px;
}

div.table_container .table td,
div.table_container .table th{
  padding: 5px 10px;
}

.table_container tfoot th:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  z-index: 99;
  background-color:#9c9c9c;
  /* box-shadow: 5px 0 5px -5px #333; */
}

.table_container tbody td:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  z-index: 99;
  background-color: #f1f1f1;
  /* box-shadow: 5px 0 5px -5px #333; */
}

.table_container .table-striped tbody tr:nth-of-type(odd) td:first-child{
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background-color: #ccc;
}

.table_container table thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: #5cb848;
}

.table_container thead th:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background-color: #5cb848;
  z-index: 999;
}
.t-content .table_container {
  max-height: 320px;
}
.table-product-detail {
  font-size: 12px;
  text-align: center;
}
.table.table-product-detail tbody td {
  font-size: 12px;
  padding: 5px 5px;
}
.table-font-bold{
  font-weight: 700;
}
.table tbody td.column-no-data{
  font-size: 16px;
  padding: 20px;
}
.no-f-border tbody tr:first-child td {
  border: none;
}
.card--customer_today_milk .table thead tr th {
  text-align: center;
  border-top: 0;
  padding: 10px 0px;
}
.card--customer_today_milk .table tbody tr td {
  padding: 10px 0;
  text-align: center;
}
.dropdown.card-toolbar-dropdown.dropdown_zindex{
  z-index: 99;
  top: 10px;
}
.card-toolbar-dropdown .dropdown-toggle {
  border: none;
  height: 25px;
}
.card-toolbar-dropdown .dropdown-menu.show{
  min-width: 100px;
  padding: 0;
}
.dropdown.card-toolbar-dropdown .dropdown-item{
  padding: .25rem 14px;
}
.old_milk_switch {
  position: absolute;
  right: 16px;
  z-index: 9;
}
.popover{
  top: auto;
}
.search-select .css-yk16xz-control,
.search-select .css-1pahdxg-control{
  border-radius: 50rem;
  height: 53px;
  padding-left: 8px;
  border:none;
  background-color: #f8f9fa!important;
}
.search-select .css-1pahdxg-control{
  border:none;
  box-shadow: none;
}

/** Datepicker CSS **/
.flatpickr-input[readonly] {
  background: none;
}

.flatpickr-months .flatpickr-month{
  height: 38px!important;
}

span.flatpickr-weekday{
  color: #fff!important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month,
.flatpickr-weekdays,
.span.flatpickr-weekday,
span.flatpickr-weekday,
.flatpickr-months .flatpickr-month,
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay{
  background: #56b333!important;
}


/** Input Error CSS **/
.input-error label,
.input-error input,
.input-error select,
.srv-validation-message {
  color: #dc3545;
}
.input-error input.form-control {
  border-color: #dc3545;
}
ons-checkbox.input-error + label {
  color: #dc3545!important;
}
ons-checkbox.input-error .checkbox--material__checkmark:before {
  border-color: #dc3545;
}

/* Profile Page */
.profile-detail-row {
  padding: 5px 0px;
  border-bottom: thin solid #eceff1;
  margin: 0px -6px;
  min-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-detail-row:last-child {
  border-bottom: 0;
}
.user-avtar{
  position: relative;
}
.edit-profile-pic {
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0 12px 29px 0 rgba(0, 0, 0, 0.09);
}
.edit-profile-pic button {
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #2d2d2d;
  font-size: 20px;
  line-height: 1.2;
}

.filtersValue ul {
    padding: 0 15px;
    margin-bottom: 0;
}
.filtersValue ul li {
    display: inline-block;
    list-style: none;
    background: #5cb848;
    padding: 5px 15px;
    border-radius: 20px;
    margin-right: 7px;
}
.filtersValue ul li span {
    color: #fff;
    font-weight: 500;
    margin-right: 7px;
}
.filtersValue ul li img {
    max-width: 14px;
    margin-left: 5px;
}
.accounts-header{
  height: 180px;
}
.accounts-header .heading-2{
  top: 50px;
  bottom: auto;
}
.t-card.dairy-account-stats {
  position: absolute;
  top: 75px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  left: 50%;
  transform: translate(-50%);
}
.dairy-account-stats span.amount {
  display: block;
  text-align: center;
  font-size: 23px;
  color: #53ae33;
}
.dairy-account-stats span.amount-text {
  display: block;
  text-align: center;
  font-size: 13px;
}
.d-account-h1 {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  margin-bottom: 13px;
}
.button--material,
.button--material:active {
  background: #34b232;
  font-family: "Poppins";
}

.button--material--small {
    min-height: 30px;
    line-height: 30px;
}
button.button--small.button {
  line-height: 25px;
  min-height: 27px;
  max-height: 27px;
}
.month-picker > .rmp-container {
  z-index: 10002!important;
}
.month-picker > .rmp-container .rmp-popup.light {
  backdrop-filter: blur(3px);
}
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, 
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
  background-color: rgb(52, 178, 50)!important;
}
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal_content {
  color: rgba(0, 0, 0, 0.87);
  width: 90vw;
  margin: 18.75px auto;
  position: relative;
  padding: 15px;
  background: #fff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0,0,0,.25);
  text-align: initial;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  border-radius: 4px;
}
.modal_body {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 74vh;
  padding: 1px;
}
.modal_body .css-4ljt47-MenuList {
  max-height: 180px;
}
.modal_close {
  position: absolute;
  top: 8px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  font-size: 20px;
}
.modal_title {
  /* margin: 0px 0px 13px; */
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  line-height: 1.6;
  letter-spacing: 0.0075em;
}
.modal_footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* border-top: 1px solid #dee2e6; */
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
  padding-top: 1rem;
}
.modal_header {
  /* border-bottom: 1px solid #ddd; */
  padding: 0px 0px 5px;
  margin-bottom: 9px;
}
.center.list-item__center.list-item--material__center {
  padding: 10px 6px 10px 0;
}
.list-item--material__left {
  min-width: 35px;
}
.center.list-item__center.list-item--material__center {
  background-image: linear-gradient(0deg,#eee,#eee 100%);
}
.d_loan_date {
  color: #afafaf;
  font-weight: 500;
}
.table td, .table th{
  border-color: #eee;
}

.dairy_slider.slick-slider {
  padding-top: 20px;
}
.slick-slider.slide-padding-0 {
  padding-top: 0;
} 
.dairy_slider .slick-dots {
  top: -11px;
  bottom: auto;
}
.dairy_slider .list-item--material:first-child {
  border-top: 1px solid #eee;
}
.dairy_slider .list-item--material:last-child .center.list-item__center.list-item--material__center,
.dairy_slider .list-item--material:last-child .right.list-item__right.list-item--material__right {
  background-image: none;
}
.dairy_loan_type_slide.slick-slider {
  padding-bottom: 20px;
} 
.dairy_loan_type_slide .slick-dots {
  top: auto;
  bottom: 18px;
}
.dairy_loan_type_slide .list-item--material:first-child {
  border-top: 1px solid #eee;
}
.dairy_loan_type_slide .list-item--material:last-child .center.list-item__center.list-item--material__center,
.dairy_loan_type_slide .list-item--material:last-child .right.list-item__right.list-item--material__right {
  background-image: none;
}
.slick-dots-top {
  padding-top: 2.2rem!important;
}
.slick-dots-top .slick-dots {
  bottom: unset;
  top: 0;
}
.title.card__title.card--material__title {
  position: relative;
}
.card--material__title {
  font-size: 22px;
}
button.no-style{
  background: none;
  border: none;
}
a.no-style {
  background: none!important;
  border: none;
}
button.no-style:after {
  display: none;
}
.table-pad-10-15 tr td,
.table-pad-10-15 tr th {
  padding: 10px 5px;
}
.cus-loan-stmt-box .card__content {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 310px);
}
.table-fix-header {
  overflow: auto;
}
.table-fix-header table thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
  margin-bottom: 2px;
  border-bottom: 2px solid #dee2e6;
}
.table-fix-header table tbody tr td:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
  margin-bottom: 2px;
}
.table-fix-header table thead th:first-child {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  margin-bottom: 2px;
  border-bottom: 2px solid #dee2e6;
  z-index: 9;
}
.hp-cst-count {
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  font-family: monospace;
  color: #333;
}
.row.green-strip {
  background: #48b234;
  color: #fff;
  font-size: 17px;
  margin: 10px -12px 5px -12px;
  height: 25px;
  line-height: 25px;
}
.action-sheet-title--material:first-child {
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #e6e6e6;
  height: 60px;
  line-height: 60px;
}
.action-sheet--material {
  border-radius: 10px 10px 0px 0px;
}
.action-sheet-title-img {
  display: inline-block;
  margin-right: 15px;
}
.action-sheet-title-img img {
  max-width: 30px;
  min-width: 30px;
}
.loan_balance_box {
  font-size: 22px;
  /* font-weight: bold; */
  /* margin: 0; */
  font-family: Roboto,Noto,sans-serif;
  color: #1f1f21;
  -webkit-font-smoothing: antialiased;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
  .dairy_loan_type_slide  .list-item--material__right {
    background-image: linear-gradient(0deg,#eee,#eee 100%);
  }
}
.inner-navigation {
  padding: 0px 12px;
}
.inner-navigation ul {
  padding: 0;
  margin: 0;
  white-space: pre;
  white-space: nowrap;
  overflow-y: auto;
  min-height: 35px;
}
.inner-navigation ul li {
  display: inline-block;
  margin: 2px 5px;
}

.inner-navigation ul li button {
  background-color: #48b234;
  border: none;
  padding: 2px 12px;
  border-radius: 30px;
  line-height: 23px;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
  position: relative;
}
.bg-blur {
  backdrop-filter: blur(5px);
}
.bg-white {
  background-color: white;
}
.action-sheet-icon--material {
  margin-right: 20px;
}
.slick-slider .slick-dots li button:before {
  font-size: 8px;
}

.radio-button__checkmark,
.checkbox__checkmark {
  z-index: 9;
}
.css-2b097c-container {
  z-index: 99;
}
#productForm .css-2b097c-container {
  z-index: auto;
}
.height100vh{
  height: calc(100vh - 160px);
} 
.scroll-auto {
  overflow: auto;
}
.alert-dialog {
  width: 80%;
  @media (min-width: map-get($grid-breakpoints, "sm")) {
    max-width: $modal-popup-max-width-mobile;  
  }
}
.printer-list {
  padding: 0px 10px!important;
}
.printer-list .printer-list-item{
  padding: 8px 5px 8px 0px;
  border-bottom: 1px solid #e4e4e4;
  max-width: 78vw;
}
.printer-list .printer-list-item:last-child {
  border-bottom: none;
}
.speed-dial.fab--bottom__right, button.fab--bottom__right, ons-fab.fab--bottom__right {
  bottom: 10px;
  right: 10px;
}
.table-tr-td-ptb-8 tr td,
.table-tr-td-ptb-8 tr th {
  padding: 8px .75rem;
}
fieldset.fieldset-with-border {
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 20px;
  /* padding: 10px 10px 0; */
}
.page-bottom-contact-us {
  background: #ddd;
  line-height: 30px;
  font-size: 0.95rem;
  @media (min-width: map-get($grid-breakpoints, "sm")) {
    font-size: 0.80rem;  
  }
}
button.app-share {
  background: none;
  border: none;
  color: #fff;
  margin: 0px 8px;
  line-height: 1;
}
input.otp_input {
  background-color: white;
  border: none;
  border-bottom: solid 1px #000000;
  margin: 0 5px;
  height: 35px;
}
.otp_not_received {
  padding: 0px 25px;
}
.otp_not_received p {
  display: flex;
  justify-content: space-between;
  margin-top: 19px;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
.otp_not_received p.resent_in {
  display: block;
}
.input-bottom-border {
  border: none;
  border-bottom: 1px solid #000000;
  min-height: 35px;
  border-radius: 0;
  padding-left: 0;
}
.input-bottom-border:focus {
  box-shadow: none;
  border-width: 2px;
}
.dairy_settings {
  position: relative;
}
td.no-border-top {
  border-top: 0;
}
td.border-top-bottom {
  border-bottom: 1px solid #dee2e6;
}
.contact-us-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  flex-flow: column;
}
.form-control::-webkit-input-placeholder {
  color: #ccc;
  font-weight: 100;
}
.subscription-header-icon {
  max-width: 50px;
  max-height: 50px;
}
.subscription-page-banner {
  background: url("./images/contact-us-banner-potrait.jpg") center;
  width: 100%;
  min-height: 100%;
  background-size: cover;
}
.subscription-purchase-modal {
  z-index: 10002!important;
}
.subscription-lock {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background: #dddddd9c;
  height: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}
.checkout-bottom-button-box {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  padding: 10px;
  box-shadow: 0px 29px 19px 20px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.reload {
  background: none;
  border: none;
}
.reload.top-right {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}
.checkout_extra_balance {
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
  font-size: 0.65rem;
  display: inline-block;
}
.SalePremiumPopup-img img{
  width: 200px;
  max-width: 100%;
}

/**** Datepicker Override cSS ****/
.MuiDialog-root {
  z-index: 10001!important;
}
.MuiInput-formControl {
  max-width: 145px;
}
label.MuiFormControlLabel-root {
 margin-bottom: unset;
}
.bill-datepicker-monthly .MuiInput-formControl {
  max-width: 70px;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #6e78f7; 
}
.MuiPickersToolbar-toolbar {
  background-color: #6e78f7;
  background-image: linear-gradient(61deg, #0ba52a 8%, #6e78f7 91%);
}
.MuiPickersToolbarText-toolbarTxt {
  color: #fff;
}
.MuiPickersMonth-monthSelected {
  color: #6e78f7;
}
.MuiButtonBase-root.MuiPickersDay-daySelected,
.MuiButtonBase-root.MuiPickersDay-daySelected:hover {
  background-color: #6e78f7 ;
}
.MuiInput--datepicker[readonly] {
  background: unset;
}
.initial_loader_container {
  height: 100%;
  justify-content: center;
  align-items: center;
}
ons-progress-circular.page_loader .progress-circular--material__primary {
  stroke: #0ba52a;
}
.fSize-1-4r {
  font-size: 1.4rem;
}
.page-bottom-contact-us a {
  margin: 0px 13px;
  color: #000;
  text-decoration: none;
}
.icon-youtube {
  color: #ff0000;
}
.icon-whatsapp {
  color: #1ebea5;
}
.icon-phone {
  color: #6e78f7;
}
.fa-rotateY-180 {
  transform: rotateY(180deg); 
}
.copyright {
  position: fixed;
  bottom: 10px;
}
.popup-searchbox button {
  position: absolute;
  right: 1px;
  z-index: 9;
  height: 100%;
}
.bg-light-blue {
  background-color: $light-blue;
}
#dairy-main-navigation .MuiBottomNavigationAction-root.Mui-selected, 
#inner-bottom-navigation .MuiBottomNavigationAction-root.Mui-selected {
  background: #738edf;
  border-color: #738edf;
  color: #fff!important;
}
#dairy-main-navigation .MuiBottomNavigationAction-root,
#inner-bottom-navigation .MuiBottomNavigationAction-root {
  min-width: auto;
  padding: 6px 10px 8px;
}
.MuiBottomNavigationAction-label.Mui-selected {
  font-size: 0.75rem!important  ;
}
.toolbar--material__left, .toolbar--material__right {
  min-width: 50px;
}
.dairy-main-navigation {
  bottom: 30px;
}
div#inner-bottom-navigation .border-right:last-child {
  border: none!important;
}
.dairy-main-navigation-action-sheet {
  bottom: 91px;
}
.dairy-main-navigation-action-sheet .action-sheet--material{
  max-height: 100%;
  overflow-x: auto;
}
.kisan-milk-detail-total .slick-slider {
  overflow: visible;
}
.t-card .slick-prev {
  left: -11px;
  z-index: 9;
}
.t-card .slick-next {
  right: -11px;
}
.t-card .slick-prev:before,
.t-card .slick-next:before {
  color: #11b232;
}
.app-slider-languages-list {
  max-height: calc(100vh - 185px);
  overflow-y: auto;
}
ons-splitter-side[mode=collapse] {
  max-width: 275px;
}
.btn-modal-close {
  color: #9e9e9e;
  right: 8px;
  top: 8px;
  position: absolute!important;
}

@import "./Height";

.rate-list-tabs .MuiButtonBase-root.Mui-disabled {
  display: none;
}

ul.nav li a.nav-link.active {
  background-color: $light-blue;
  color: #fff;
}

a.MuiButton-root:hover {
  // color: #fff;
}

.user-menu-popover::after{
  content: "";
  height: 25px;
  width: 25px;
  position: absolute;
  background-color: white;
  top: -12px;
  left: 82%;
  transform: rotate(45deg);
}
.MuiListItem-root.menu-item-active .menu-item-icon-image-active {
  display: block;
}
.MuiListItem-root.menu-item-active .menu-item-icon-image {
  display: none;
}
.MuiAvatar-root.customer-avatar {
  width: 60px;
  height: 60px;
}
.rate-list-tab .MuiTab-wrapper {
  flex-direction: row;
}
.MuiAvatar-root.sidebar-user-avtar {
  width: 100%;
  height: 100%;
}
label[role="button"]:focus, label[role="button"]:focus-within {
  box-shadow: 0 0 0 0.2rem #0000009c;
}

@media (max-width:320px) {
  body {
    font-size: 14px;
  }
}

.rate_tab-btn-more-add {
  display: flex ;
  padding: 0 2px ;
}
.rate_tab-btn-more-add:focus {
  box-shadow: none;
}
.rate-list-tabs {
  gap: 0.2rem;
  align-items: center;
}


.customer-selection-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap ;
}
.code-input-button{
  display: flex;
  flex-wrap: nowrap;
}
.code-input {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.btn-decrement,
.btn-increment {
  padding: 0.3rem 0.3rem;
}
.select-custumer-container{
  width: 100%;
}
.customer-select {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  min-width: 0;
  margin-bottom: 0;
}
.code-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3.4rem;
}
.select-customer-group{
  display: flex;
  align-items: center;
  gap: 1.3rem;
}